import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CaseStudiesPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Case Studies" />
    <h1 style={styles.header}>Case Studies</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Learn how we do it.</h3>
      <p>
        <span style={{fontWeight: 600, color: 'rgb(141, 27, 56)'}}>Charitable Giving</span><br/>
        <span style={{fontWeight: 600}}>The Situation</span><br/>
        The client owned highly appreciated stock of a company that was being bought for cash, creating a $680,000 tax 
        burden. The client had goals of increasing his personal income and simultaneously preparing to leave a 
        substantial legacy to his university alma mater.<br/>
      </p>
      <p>
        <span style={{fontWeight: 600}}>The Proposed Solution</span><br/>
        The Coastal Advisory Group met with the client, the client’s attorney, and the client’s CPA to establish a 
        Charitable Remainder Unit Trust. This type of trust was chosen because it provides benefits over a Charitable 
        Remainder Trust by allowing additional gifts to be made throughout the client’s life.
      </p>
      <p>
        The trust also provides for the client to gift the trust with additional contributions, such as real estate or 
        stock, which the trust can then sell with no capital gains tax incurred.
      </p>
      <p>
        This solution provides the client substantial tax savings, including an immediate tax write-off, and a lifetime 
        stream of income. The single asset was converted into a diversified portfolio managed by the Coastal Advisory 
        Group.
      </p>
      <p>
        The university receives the Charitable Remainder Unit Trust assets upon the client’s death as part of his 
        legacy.
      </p>
      <hr/>
      <p>
        <span style={{fontWeight: 600, color: 'rgb(141, 27, 56)'}}>Legacy Planning</span><br/>
        <span style={{fontWeight: 600}}>The Situation</span><br/>
        The client wanted to maximize his gifting in order to minimize the estate tax burden on his heirs.<br/>
      </p>
      <p>
        <span style={{fontWeight: 600}}>The Proposed Solution</span><br/>
        The Coastal Advisory Group worked with the client’s attorney to create a Christofani Trust, which allows the 
        grantor to gift his linear descendants, including children, grandchildren and great-grandchildren.
      </p>
      <p>
        This allowed the client to make a $12,000 gift (now a $15,000 limit) each year to his three children and sixteen grandchildren for 
        a total of $228,000 a year. Additionally, the trust allows the estate to increase its gifting as more children 
        are born into the family, thus lessening the estate’s ongoing tax burden.
      </p>
      <p>
        Using this trust as the investment strategy for estate planning, the grantor’s last wish is able to be 
        honored: that he maximize his gifts to heirs during his lifetime in order to reduce taxes on the remaining 
        estate upon his death.
      </p>
      <hr/>
      <p>
        <span style={{fontWeight: 600, color: 'rgb(141, 27, 56)'}}>Asset Protection</span><br/>
        <span style={{fontWeight: 600}}>The Situation</span><br/>
        A young client had built a highly successful company within the technology industry. He was looking for a means 
        to protect his financial success accumulated by an outstanding business transaction.<br/>
        His goal was to insure his total assets would escape any liability action brought forth against him, and would 
        protect his assets from both personal and professional situations that might arise in the future.
      </p>
      <p>
        <span style={{fontWeight: 600}}>The Proposed Solution</span><br/>
        The Coastal Advisory Group provided a selection of legal trust experts and tax specialists to the client for his 
        choosing. Once the team of advisors were in place, we worked with them to create legal and financial solutions 
        to meet his main objective while also providing benefits he had not yet considered.
      </p>
      <p>
        The final investment strategy was to implement an Irrevocable Trust that protects all his assets while also 
        providing the potential for a yearly income stream to be executed at his request. Additionally, through the 
        trust and other safeguards, his assets became insulated and unassailable by third parties. The client also 
        gained tax advantages that he had not considered.
      </p>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default CaseStudiesPage
